






/* eslint-disable camelcase */
import Vue from 'vue';
import { computed, defineComponent, Ref } from '@vue/composition-api';
import { ACL } from '@/modules/acl/acl-service';
import UserService from '@/services/user-service';

export default defineComponent({
  setup() {
    const acl: Ref<boolean> = computed(() => {
      return ACL.can('admin.browse');
    });
    const url: Ref<string> = computed(() => {
      return UserService.instance.user.userable ? '/admin/route-transport/month?rnis' : '/admin/?rnis';
    });
    return {
      acl,
      url,
    };
  },
});
